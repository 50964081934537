import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select, { createFilter } from 'react-select';
import { customStyles, CheckboxValueContainer, DropdownOption } from '../utils';
import { FixedSizeList as List } from "react-window";

interface VehicleDropdownProps {
  name: string;
  options: any[];
  value: string[]; 
  isMulti: boolean;
  placeholder: string;
}

const VehicleDropdown = ({ name, options, value, isMulti, placeholder }: VehicleDropdownProps) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const MenuList = function MenuList(props) {
    const height = 50; 
    const { options, children, maxHeight, getValue } = props;
    const itemCount = children.length; 
    const initialOffset = options.indexOf(getValue()[0]) * height;
  
    // Ensure that the height of the menu matches the number of items without extra space
    const calculatedHeight = Math.min(maxHeight, itemCount * height);
  
    return (
      <List
        height={calculatedHeight} 
        itemCount={itemCount}
        itemSize={height} 
        initialScrollOffset={initialOffset} 
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  useEffect(() => {
    const sanitizedValue = Array.isArray(value) ? value : [];  // Ensuring value is an array
    const selected = options.filter(option => sanitizedValue.includes(option.value));
    setSelectedOptions(selected);
  }, [value, options]);

  const handleChange = (selected: any) => {
    setSelectedOptions(selected || []);
  };


  return (
    <Select
      filterOption={createFilter({ ignoreAccents: false })}
      options={options}
      components={{ MenuList, Option: DropdownOption, ValueContainer: CheckboxValueContainer }}
      value={selectedOptions}
      isMulti={isMulti}
      isSearchable
      name={name}
      onChange={handleChange}
      styles={customStyles} 
      placeholder={placeholder}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
    />
  );
};

VehicleDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.array,
}

VehicleDropdown.defaultProps = {
  isMulti: true,
  placeholder: "",
  value: [], 
};

export default VehicleDropdown;
