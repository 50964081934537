import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shipment--roundtrip-oneway"
export default class extends Controller {
  static targets = ["hourInputField", "minuteInputField"];

  connect() {}

  roundTrip() {
    this.resetFields(4, 0);
  }

  onewayTrip() {
    this.resetFields(0, 0);
  }

  resetFields(hourIndex, minuteIndex) {
    if (this.hasHourInputFieldTarget && this.hasMinuteInputFieldTarget) {
      this.hourInputFieldTarget.selectedIndex = hourIndex;
      this.minuteInputFieldTarget.selectedIndex = minuteIndex;
    }
  }
}
