import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Rails from '@rails/ujs';

interface NotificationFieldsProps {
  smsNotification: boolean;
  voiceNotification: boolean;
  mailNotification: boolean;
  inappNotification: boolean;
  isGroundTransportUser: boolean;
}

const NotificationFields = ({
  smsNotification,
  voiceNotification,
  mailNotification,
  inappNotification,
  isGroundTransportUser
}: NotificationFieldsProps) => {
  const [smsChecked, setSmsChecked] = useState(smsNotification);
  const [voiceChecked, setVoiceChecked] = useState(voiceNotification);
  const [mailChecked, setMailChecked] = useState(mailNotification);
  const [inappChecked, setInappChecked] = useState(inappNotification);

  const smsRef = useRef(null);
  const voiceRef = useRef(null);
  const mailRef = useRef(null);
  const inappRef = useRef(null);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (!smsChecked && !mailChecked && !inappChecked && !voiceChecked) {
      var notificationModalBtn = document.getElementById("notificationModalBtn");
      if(notificationModalBtn)
        notificationModalBtn.click()
    } else {
      // submitForm();
      var formSave = document.querySelector("#preferenceForm input[type='submit']");
      if(formSave) formSave.removeAttribute("disabled");
    }
  }, [smsChecked, mailChecked, inappChecked, voiceChecked]);

  const submitForm = () => {
    if (!smsChecked && !mailChecked && !inappChecked && !voiceChecked) return

    var preferenceForm = document.getElementById("preferenceForm");
    if (preferenceForm)
      Rails.fire(preferenceForm, 'submit');
  }

  const handleSmsChange = () => {
    setSmsChecked(!smsChecked);
  }

  const handleVoiceChange = () => {
    setVoiceChecked(!voiceChecked);
  }

  const handleMailChange = () => {
    setMailChecked(!mailChecked);
  }

  const handleInappChange = () => {
    setInappChecked(!inappChecked);
  }

  return (
    <div className="d-flex flex-row px-2">
      <div className="w-3-12 d-flex flex-row align-items-center">
        <div className="custom-control-switch custom-switch">
          <input id="sms-notification"
            className="custom-control-switch-input"
            type="checkbox"
            defaultChecked={smsChecked}
            onChange={handleSmsChange}
            ref={smsRef} />
            <input type="hidden" name="notification_preference[sms_notification]" value={smsChecked} />
          <label className="custom-control-switch-label" for="sms-notification"></label>
        </div>
        <span className="font-size-sm">
          SMS
        </span>
      </div>
      {isGroundTransportUser &&
        <div className="w-3-12 d-flex flex-row align-items-center">
          <div className="custom-control-switch custom-switch">
            <input id="voice-notification"
              className="custom-control-switch-input"
              type="checkbox"
              defaultChecked={voiceChecked}
              onChange={handleVoiceChange}
              ref={voiceRef} />
              <input type="hidden" name="notification_preference[voice_notification]" value={voiceChecked} />
            <label className="custom-control-switch-label" for="voice-notification"></label>
          </div>
          <span className="font-size-sm">
            Voice
          </span>
        </div>
      }
      <div className="w-3-12 d-flex flex-row align-items-center">
        <div className="custom-control-switch custom-switch">
          <input id="email-notification"
            className="custom-control-switch-input"
            type="checkbox"
            defaultChecked={mailChecked}
            onChange={handleMailChange}
            ref={mailRef} />
            <input type="hidden" name="notification_preference[email_notification]" value={mailChecked} />
          <label className="custom-control-switch-label" for="email-notification"></label>
        </div>
        <span className="font-size-sm">
          Email
        </span>
      </div>
      <div className="w-3-12 d-flex flex-row align-items-center">
        <div className="custom-control-switch custom-switch">
          <input id="inapp-notification"
            className="custom-control-switch-input"
            type="checkbox"
            defaultChecked={inappChecked}
            onChange={handleInappChange}
            ref={inappRef} />
            <input type="hidden" name="notification_preference[inapp_notification]" value={inappChecked} />
          <label className="custom-control-switch-label" for="inapp-notification"></label>
        </div>
        <span className="font-size-sm">
          In-app
        </span>
      </div>
    </div>
  );
}

NotificationFields.propTypes = {
  name: PropTypes.string.isRequired,
  ratingNames: PropTypes.string.isRequired,
  directUploadUrl: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default NotificationFields;
